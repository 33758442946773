<template>
  <div>
    <div v-if="requestTransfer">
      <h2 class="headline--md text-bold text-uppercase">request detail</h2>
      <div v-if="requestTransfer" class="box box--md box--white mt-12">
        <table class="table table--list three-items">
          <tbody>
            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Email</p>
                  <h2 class="headline--md mt-4">{{ requestTransfer.adminRequest.email }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Wallet address receive withdraw</p>
                  <h2 class="headline--md mt-4">{{ requestTransfer.adminRequest.address }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Withdraw request amount</p>
                  <h2 class="headline--md mt-4 text-uppercase">
                    {{ requestTransfer.amount | number_with_commas }}
                    {{ requestTransfer.token ? requestTransfer.token.symbol : '' }}
                  </h2>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Request date</p>
                  <h2 class="headline--md mt-4">{{ requestTransfer.requestAt | moment_lts }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Expire date</p>
                  <h2 class="headline--md mt-4">{{ requestTransfer.expireAt | moment_lts }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Status</p>
                  <h2 v-if="expiredStatus" class="headline--md mt-4 text-gray text-bold text-uppercase">expired</h2>
                  <h2 class="headline--md mt-4" v-else v-html="formatRequestTransferStatus(requestTransfer.status)" />
                  <button v-if="canRetry" @click="onApproveRequest" class="btn btn--clear">
                    <toko-retry-icon /> <span class="text-sm text-purple">Retry</span>
                  </button>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Request Note</p>
                  <h2 class="headline--md mt-4">
                    {{ requestTransfer.requestNote ? requestTransfer.requestNote : '_' }}
                  </h2>
                </div>
              </td>
              <td v-if="requestTransfer.tnxHashRequest">
                <div class="statistic">
                  <p class="statistic__title text-warm">Transaction hash</p>
                  <router-link
                    class="headline--md mt-4 link--primary link--underline"
                    :to="`/etherscan/${requestTransfer.tnxHashRequest}`"
                    >{{ requestTransfer.tnxHashRequest }}</router-link
                  >
                </div>
              </td>
              <td v-else />
              <td />
            </tr>

            <tr v-if="enableReviewAction">
              <td class="has-border" colspan="3">
                <p class="text-sm text-warm">Note: If the request is not approved in 24 hours, it will be expired.</p>
                <div class="text-center mt-24">
                  <button @click="isConfirmRejectRequest = true" class="btn btn--red-bordered">Reject</button>
                  <button class="btn btn--primary" @click="isConfirmApproveRequest = true">Approve</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="requestTransfer && requestTransfer.adminApprove">
      <h2 class="headline--md text-bold text-uppercase mt-24">Review detail</h2>
      <div class="box box--md box--white mt-12">
        <table class="table table--list three-items">
          <tbody>
            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Email</p>
                  <h2 class="headline--md mt-4">{{ requestTransfer.adminApprove.email }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Full name</p>
                  <h2 class="headline--md mt-4">{{ requestTransfer.adminApprove.full_name }}</h2>
                </div>
              </td>
              <td>
                <div v-if="isRejected" class="statistic">
                  <p class="statistic__title text-warm">Reject date</p>
                  <h2 class="headline--md mt-4">{{ requestTransfer.rejectAt | moment_lts }}</h2>
                </div>

                <div v-else-if="isApproving" class="statistic">
                  <p class="statistic__title text-warm">Approve date</p>
                  <h2 class="headline--md mt-4">{{ requestTransfer.approveAt | moment_lts }}</h2>
                </div>
                <p v-else />
              </td>
            </tr>

            <tr>
              <td>
                <div v-if="isRejected" class="statistic">
                  <p class="statistic__title text-warm">Reject Note</p>
                  <h2 class="headline--md mt-4">
                    {{ requestTransfer.rejectNote ? requestTransfer.rejectNote : '_' }}
                  </h2>
                </div>
                <div v-else-if="isApproving" class="statistic">
                  <p class="statistic__title text-warm">Approve Note</p>
                  <h2 class="headline--md mt-4">
                    {{ requestTransfer.approveNote ? requestTransfer.approveNote : '_' }}
                  </h2>
                </div>
                <p v-else />
              </td>
              <td>
                <div v-if="requestTransfer.tnxHashApprove" class="statistic">
                  <p class="statistic__title text-warm">Transaction hash</p>
                  <router-link
                    class="headline--md mt-4 link--primary link--underline"
                    :to="`/etherscan/${requestTransfer.tnxHashApprove}`"
                    >{{ requestTransfer.tnxHashApprove }}</router-link
                  >
                </div>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="enableReviewAction">
      <loading :open="waiting" />

      <confirm-dialog
        v-if="isConfirmRejectRequest"
        :open.sync="isConfirmRejectRequest"
        confirmText="Are you sure to reject this withdraw request ?"
        confirmButtonText="Continue"
        confirmButtonClass="text-purple"
        @confirm="onRejectRequest"
      >
        <div class="form-group mt-24">
          <textarea
            v-model="rejectNote"
            placeholder="Reject note"
            rows="4"
            cols="50"
            type="textarea"
            class="form-control"
          />
        </div>
      </confirm-dialog>

      <confirm-dialog
        v-if="isConfirmApproveRequest"
        :open.sync="isConfirmApproveRequest"
        confirmText="Are you sure to approve this withdraw request ?"
        confirmButtonText="Continue"
        confirmButtonClass="text-purple"
        @confirm="onApproveRequest"
      >
        <div class="form-group mt-24">
          <textarea
            v-model="approveNote"
            placeholder="Approve note"
            rows="4"
            cols="50"
            type="textarea"
            class="form-control"
          />
        </div>
      </confirm-dialog>
    </div>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { CF_REQUEST_TRANSFER_STATUS } from '@/utils/key-mapping'
import requestTransferStatus from '@/mixins/request-transfer-status'
import timeCompare from '@/mixins/time-compare'
import toggleLoading from '@/mixins/toggle-loading'
const Loading = () => import('@/components/Loading')
const ConfirmDialog = () => import('@/components/ConfirmDialog')
const TokoRetryIcon = () => import('@/ui/toko-icons/TokoRetry')

export default {
  data() {
    return {
      isConfirmRejectRequest: false,
      isConfirmApproveRequest: false,
      isNotiConnectMetamaskOpen: false,
      requestStatus: CF_REQUEST_TRANSFER_STATUS,
      rejectNote: '',
      approveNote: ''
    }
  },

  mixins: [requestTransferStatus, timeCompare, toggleLoading],

  computed: {
    ...mapState('dapp', ['waiting', 'isConnected']),

    ...mapState('requestTransfer', ['requestTransfer']),

    enableReportDate() {
      return this.requestTransfer && this.requestTransfer.status >= this.requestStatus.rejected
    },

    enableReviewAction() {
      return (
        this.requestTransfer &&
        this.currentUser &&
        !this.expiredStatus &&
        this.requestTransfer.adminRequestId !== this.currentUser.ID &&
        this.requestTransfer.status === this.requestStatus.requestSuccess
      )
    },

    expiredStatus() {
      return (
        this.requestTransfer &&
        this.requestTransfer.status < this.requestStatus.rejected &&
        this.isExpiredTime(this.requestTransfer.expireAt_milliseconds)
      )
    },

    isRejected() {
      return this.requestTransfer && this.requestTransfer.status === this.requestStatus.rejected
    },

    isApproving() {
      return this.requestTransfer && this.requestTransfer.status > this.requestStatus.rejected
    },

    canRetry() {
      return (
        this.requestTransfer &&
        !this.isExpiredTime(this.requestTransfer.expireAt_milliseconds) &&
        this.currentUser &&
        this.requestTransfer.adminRequest_id !== this.currentUser.ID &&
        this.requestTransfer.status === this.requestStatus.approveFailed
      )
    }
  },

  components: {
    Loading,
    ConfirmDialog,
    TokoRetryIcon
  },

  methods: {
    ...mapMutations('requestTransfer', ['CLEAR_REQUEST_TRANSFER']),

    ...mapActions('requestTransfer', ['getRequestTransfer', 'rejectRequestTransfer']),

    ...mapActions('smartContract', ['getSmartContracts']),

    ...mapActions('dapp', ['web3Connect', 'approvedRequestTransferByAdmin']),

    onRejectRequest() {
      this.toggleLoading()
      this.rejectRequestTransfer({
        id: parseInt(this.$route.params.id),
        rejectNote: this.$sanitize(this.rejectNote)
      }).finally(() => {
        this.toggleLoading()
        this.isConfirmRejectRequest = false
      })
    },

    async onApproveRequest() {
      await this.web3Connect()
      if (!this.isConnected) return

      this.toggleLoading()
      this.approvedRequestTransferByAdmin({
        requestData: this.requestTransfer,
        approveNote: this.$sanitize(this.approveNote)
      }).finally(() => {
        this.toggleLoading()
        this.isConfirmApproveRequest = false
      })
    }
  },

  async created() {
    this.CLEAR_REQUEST_TRANSFER()

    try {
      await this.getSmartContracts()
      await this.getRequestTransfer(this.$route.params.id)
    } catch (e) {
      this.toggleLoading()
      throw e
    }

    this.toggleLoading()
  }
}
</script>
